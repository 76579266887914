import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { snakeToPascal } from '../../utils/CaseConverters';
import dynamic from 'next/dynamic';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './StreamField.nordr.module.scss';
import stylesFolkhem from './StreamField.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

const ArticleList = dynamic(() => import('../ArticleList'));
const CardCta = dynamic(() => import('../CardCta'));
const CardFact = dynamic(() => import('../CardFact'));
const CardImage = dynamic(() => import('../CardImage'));
const CardMedia = dynamic(() => import('../CardMedia'));
const CardResidencePicker = dynamic(() => import('../CardResidencePicker'));
const CodeBlock = dynamic(() => import('../CodeBlock'));
const ContactList = dynamic(() => import('../ContactList'));
const Embed = dynamic(() => import('../Embed'));
const ExternalProjects = dynamic(() => import('../ExternalProjects'));
const FormBlock = dynamic(() => import('../FormBlock'));
const FormContact = dynamic(() => import('../FormContact'));
const FormContactSubject = dynamic(() => import('../FormContactSubject'));
const Gallery = dynamic(() => import('../Gallery'));
const Iframe = dynamic(() => import('../Iframe'));
const ListProjectsAndObjects = dynamic(() => import('../ListProjectsAndObjects'));
const MenuBlock = dynamic(() => import('../MenuBlock'));
const ProjectFacts = dynamic(() => import('../ProjectFacts'));
const ProjectGallery = dynamic(() => import('../ProjectGallery'));
const ProjectInTheArea = dynamic(() => import('../ProjectInTheArea'));
const ProjectObjects = dynamic(() => import('../ProjectObjects'));
const ProjectPresentation = dynamic(() => import('../ProjectPresentation'));
const ProjectViewings = dynamic(() => import('../ProjectViewings'));
const RelatedProjects = dynamic(() => import('../RelatedProjects'));
const StyleChooser = dynamic(() => import('../StyleChooser'));
const Wysiwyg = dynamic(() => import('../Wysiwyg'));
const Quote = dynamic(() => import('../Quote'));
const Stepper = dynamic(() => import('../Stepper'));
const ObjectCostCalculator = dynamic(() => import('../ObjectCostCalculator'), {ssr: false});

const componentsCache = {
    ArticleList,
    CardCta,
    CardFact,
    CardImage,
    CardMedia,
    CardResidencePicker,
    CodeBlock,
    ContactList,
    Embed,
    ExternalProjects,
    FormBlock,
    FormContact,
    FormContactSubject,
    Gallery,
    Iframe,
    ListProjectsAndObjects,
    MenuBlock,
    ProjectFacts,
    ProjectGallery,
    ProjectInTheArea,
    ProjectObjects,
    ProjectPresentation,
    ProjectViewings,
    Quote,
    RelatedProjects,
    StyleChooser,
    Wysiwyg,
    Stepper,
    ObjectCostCalculator,
}

class StreamField extends React.PureComponent {
    render() {
        const { modifiers, pageType, layout } = this.props;
        let { items } = this.props;
        const theme = this.context;
        const styles = getStyles(theme);

        if (!items.length) {
            return null;
        }

        items = items.map((item) => {
            return {
                ...item,
                component: snakeToPascal(item.component)
            }
        });

        const dynamicComponents = items.map((item, index) => {
            const Component =
                item.component === 'StreamField'
                    ? StreamField
                    : componentsCache[item.component]

            let itemAhead = items[index+1];
            if (itemAhead?.component === "MenuBlock") {
                itemAhead = items[index+2];
            }

            if(!Component) {
                console.error(`No such component: ${item.component}`)
            }

            if (!Component) {
                return null;
            }

            if (item.component === 'ListProjectsAndObjects' && pageType) {
                item  = {
                    ...item,
                    layout,
                }
            }

            if (item.component === 'StreamField' && pageType) {
                item  = {
                    ...item,
                    pageType: pageType,
                }
            }

            if (item.component === 'CardImage' && pageType) {
                item = {
                    ...item,
                    modifiers: ['WithinPageType'+pageType],
                }
            }

            if (item.component === 'Wysiwyg') {
                item = {
                    ...item,
                    className: 'Wysiwyg '+styles['StreamField__Component--WysiwygItem'],
                }
            }

            return (
                <div
                    key={index}
                    id={item.id}
                    className={classNames(
                        styles['StreamField__Component'],
                        styles['StreamField__Component--'+item.component],
                        styles['StreamField__Component--'+item.component+'AndBefore'+itemAhead?.component],
                    )}
                >
                    <Component {...item} />
                </div>
            );
        });

        return (
            <div
                className={classNames(
                    styles['StreamField'],
                    { [styles['StreamField--WithinPageType'+pageType]]: !!pageType },
                    modifiers.map((m) => styles['StreamField--' + m])
                )}>
                {dynamicComponents}
            </div>
        );
    }
}

StreamField.propTypes = {
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    modifiers: PropTypes.arrayOf(PropTypes.string),
    pageType: PropTypes.string,
    layout: PropTypes.string,
};

StreamField.defaultProps = {
    items: [],
    modifiers: ['ContentWidth'],
    layout: '',
};

StreamField.contextType = ThemeContext;

export default StreamField;
